export const environment = {
  envName: 'PROD',
  production: true,
  firebase: {
    apiKey: "AIzaSyB5JN2b5LtkzaK8RgCi3AAj0GtAcr0NvRY",
    authDomain: "inovanti-prd.firebaseapp.com",
    databaseURL: "https://inovanti-prd.firebaseapp.com",
    projectId: "inovanti-prd",
    storageBucket: "inovanti-prd.appspot.com",
    messagingSenderId: "308918595319",
    appId: "1:308918595319:web:d9badaf9404e1f2b95edf2"
  },

  tenants: [
    {
      tenant: "DiscoveryBank-9zwbz",
      name: "drxbank",
      primaryColor: "0xffFE7E13",
      overPrimary: "#FFFFFF",
      icon: "https://storage.googleapis.com/inovanti-prd.appspot.com/tenant-assets/DiscoveryBank/icon",
      apiKey: "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJEaWxldHRhIFNvbHV0aW9ucyIsImlhdCI6MTY3Nzc2MTc4MDI5MiwiYXVkIjoiaW5vdmFudGktcHJkIiwic3ViIjoiRGlzY292ZXJ5QmFuayIsImp0aSI6IjA3ZjY4YzEyLTJmZDUtNDQ4Mi1iMjk5LWU1NWI2MWUxYTgwZCIsInRlbmFudElkIjoiRGlzY292ZXJ5QmFuay1nd2IyNiJ9.-ByE_Xdxi0Q3c5OQpNr4igVbVnNzaULleg_NSC8ZdtjfjD3a_3yKOf8tIFqolMFtnGRaz9wh01YH6NWv7SB1MA",
      client: "matera",
      clientSuite: false,
      infoHeader: false,
      biz: false,
      apiUrl: 'https://inovanti-prod-vpc-dot-inovanti-prd.uc.r.appspot.com',
      appSupport: true,
      orderSupport: false
    },
    {
      tenant: "XOBank-e995g",
      name: "xobank",
      primaryColor: "0xff9B0000",
      overPrimary: "#FFFFFF",
      icon: "https://storage.googleapis.com/inovanti-prd.appspot.com/tenant-assets/XOBank/icon",
      apiKey: "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJEaWxldHRhIFNvbHV0aW9ucyIsImlhdCI6MTY4MTI2NjI1NzM1MCwiYXVkIjoiaW5vdmFudGktcHJkIiwic3ViIjoiWE9CYW5rIiwianRpIjoiNDJiMmZlZjItZmZhOS00ZTZlLTk4MzAtNDExMDJlOTRmMDY2IiwidGVuYW50SWQiOiJYT0JhbmstZTk5NWcifQ.Iiolcm0WogSP6YFHMQkfwlvAT2W1phUnwrJlhAbIuI_OdPSniTYqbH6fFSPUOkQNCzjejPkHuHWDSehHTFTTYQ",
      client: "matera",
      clientSuite: false,
      infoHeader: false,
      biz: false,
      appSupport: true,
      orderSupport: false,
      apiUrl: 'https://inovanti-prod-vpc-dot-inovanti-prd.uc.r.appspot.com'
    },
    {
      tenant: "LionsBank-mej9x",
      name: "lionsbank",
      primaryColor: "0xff001A2D",
      overPrimary: "#FFFFFF",
      icon: "https://storage.googleapis.com/inovanti-prd.appspot.com/tenant-assets/LionsBank/icon",
      apiKey: "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJEaWxldHRhIFNvbHV0aW9ucyIsImlhdCI6MTY4NDQ1NjgwNDY4NywiYXVkIjoiaW5vdmFudGktcHJkIiwic3ViIjoiTGlvbnNCYW5rIiwianRpIjoiYTEwOGY0ZGItZmVjMy00NDgzLWIwNWEtYmM1MTI1MThiZmUzIiwidGVuYW50SWQiOiJMaW9uc0JhbmstbWVqOXgifQ.ID-0sfZU5dzhXXTsm1C617j0sZEg7SQDHT1oiWFp4RgCjsAbFYRpWQtNuXgWpuKxI40qAuplwe3R9XPgXbPnXQ",
      client: "matera",
      clientSuite: false,
      infoHeader: false,
      biz: false,
      apiUrl: 'https://inovanti-prod-vpc-dot-inovanti-prd.uc.r.appspot.com',
      appSupport: true,
      orderSupport: false
    },
    {
      tenant: "k2imobank-7a114",
      name: "k2imobank",
      primaryColor: "0xffD8A939",
      overPrimary: "#FFFFFF",
      icon: "https://storage.googleapis.com/inovanti-prd.appspot.com/tenant-assets/k2imobank/icon",
      apiKey: "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJEaWxldHRhIFNvbHV0aW9ucyIsImlhdCI6MTcyNjcxMzU3MTg2MSwiYXVkIjoiaW5vdmFudGktcHJkIiwic3ViIjoiazJpbW9iYW5rIiwianRpIjoiYmY1MTExNGYtZTcwZS00YTk4LTlmNzQtZjBkN2U0NTkyODQ5IiwidGVuYW50SWQiOiJrMmltb2JhbmstN2ExMTQifQ.nq8at-ajMDJiK_cAwYUGoqi3kOSxazr6kvofEhWTPmiHFDXTOFtirRbuhkdeC_TAWfz4vWkiEh6-lQMYCQsGdw",
      client: "matera",
      clientSuite: false,
      infoHeader: false,
      biz: false,
      apiUrl: 'https://inovanti-prod-vpc-dot-inovanti-prd.uc.r.appspot.com',
      appSupport: true,
      orderSupport: false
    },
    {
      tenant: "pgbank-jplbe",
      name: "pgbank",
      primaryColor: "0xff000547",
      overPrimary: "#FFFFFF",
      icon: "https://storage.googleapis.com/inovanti-prd.appspot.com/tenant-assets/pgbank/icon",
      apiKey: "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJEaWxldHRhIFNvbHV0aW9ucyIsImlhdCI6MTcyODA1ODM4OTI1MCwiYXVkIjoiaW5vdmFudGktcHJkIiwic3ViIjoicGdiYW5rIiwianRpIjoiMmZhN2M0Y2EtODcyZS00NDBiLTk3NWEtMjhjNTg3NTBlN2JmIiwidGVuYW50SWQiOiJwZ2JhbmstanBsYmUifQ.Z91BIbzSuteUUxc26ytd-2t3Yq3zVVTQupuPsJ69xnf1pz2fKQIf2jD7e0aQhfb_9cUZjQwvgY0HvWXHfo36rQ",
      client: "matera",
      clientSuite: false,
      infoHeader: false,
      biz: false,
      apiUrl: 'https://inovanti-prod-vpc-dot-inovanti-prd.uc.r.appspot.com',
      appSupport: true,
      orderSupport: false
    },
    {
      tenant: "profitbank-55s9h",
      name: "profitbank",
      primaryColor: "0xff000547",
      overPrimary: "#FFFFFF",
      icon: "https://storage.googleapis.com/inovanti-prd.appspot.com/tenant-assets/profitbank/icon",
      apiKey: "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJEaWxldHRhIFNvbHV0aW9ucyIsImlhdCI6MTcyOTc3ODUzMjY0NCwiYXVkIjoiaW5vdmFudGktcHJkIiwic3ViIjoicHJvZml0YmFuayIsImp0aSI6ImJmZjAxNjBkLWYzOWYtNDRhNy05MTEzLTRmOGUzNzA2YmM1MiIsInRlbmFudElkIjoicHJvZml0YmFuay01NXM5aCJ9.0KgrfxN5uuOXUkhVDaZg4eTGybYz1uERN6jwikwPwObGB6Nzk8phfG9qSF4a7sKI5bG1SYpFBQ3y9GgK45eA5A",
      client: "matera",
      clientSuite: false,
      infoHeader: false,
      biz: false,
      apiUrl: 'https://inovanti-prod-vpc-dot-inovanti-prd.uc.r.appspot.com',
      appSupport: true,
      orderSupport: false
    }
  ],
  apiUrl: 'https://inovanti-prod-vpc-dot-inovanti-prd.uc.r.appspot.com',
  keyEncryptAuth: 'oGTy7QtyHr48GE6re5KTckkGnbm6oo7SwRHsi2gqjapqflY9hjTAJ4GyUtt9YrSK',
  account: '413453',
  branch: '3100',
  wallet: '109',
  bank: '341',
  currency: '9'
};